// import request from "@/utils/request";
import axios from 'axios';
import store from '@/store/index';
import { service } from '@/utils/request';

// 下载文件
export const downloadFileApi = (url: string) => {
  return axios.get(url, { responseType: 'blob' });
};

// 获取聊天会话列表
export const getChatListApi = (params: any) => {
  // 第二次调用时，取消第一次调用的请求
  const CancelToken = axios.CancelToken;
  const chatListSource = CancelToken.source();
  store.commit('setChatListSource', chatListSource);
  return service({
    url: '/v1/chat-sessions',
    method: 'get',
    params: params,
    cancelToken: chatListSource.token,
  });
};

// 获取联系人列表
export const getContactsListApi = (params: any) => {
  return service({
    url: '/v1/contacts',
    method: 'get',
    params: params,
  });
};

// 获取通讯录列表
export const getPhoneListApi = (params: any) => {
  // 第二次调用时，取消第一次调用的请求
  const CancelToken = axios.CancelToken;
  const phoneSource = CancelToken.source();
  // console.log(source, "source");

  store.commit('setPhoneSource', phoneSource);
  return service({
    url: '/v1/calls',
    method: 'get',
    params: params,
    cancelToken: phoneSource.token,
  });
};

// 获取聊天记录列表
export const getChatContentListApi = (params: any) => {
  // 第二次调用时，取消第一次调用的请求
  const CancelToken = axios.CancelToken;
  const source = CancelToken.source();
  // console.log(source, "source");

  store.commit('setSource', source);
  return service({
    url: '/v1/messages',
    method: 'get',
    params: params,
    cancelToken: source.token,
  });
  // .catch((err) => {
  //   // 这里主要是判断这个错误是取消请求导致的还是其他原因导致的
  //   if (axios.isCancel(err)) {
  //     console.log(err.message);
  //   }
  // });
};

// 获取文件下载（在线预览）地址
export const getFileLinkApi = (data: any) => {
  return service({
    url: '/v1/download-url',
    method: 'post',
    data: data,
  });
};

// ios同步获取联系人列表
export const syncContactsListApi = (data: any) => {
  return service({
    url: '/v1/icloud/contacts',
    method: 'post',
    data: data,
  });
};
